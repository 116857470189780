import axios from "axios";
import { Config } from "../config";

const client = axios.create({
    baseURL: Config.apiBaseUrl,
});

client.interceptors.request.use(
    async (config: any) => {
        const token = localStorage.getItem("token");

        if (token && token !== "") {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

export default client;
