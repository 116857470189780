import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { apiAuth } from "../api";
import { useSearchParams } from "react-router-dom";

const SignInPage = () => {
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Бөглөх шаардлагатай."),
      password: Yup.string().required("Бөглөх шаардлагатай."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const client = params.get("client");

      if (!client) {
        toast.error("Сервисийн тохиргоо алдаатай байна.");
        setLoading(false);
        return;
      }

      const result = await apiAuth.signIn(
        client,
        values?.email,
        values?.password
      );

      if (!result.success) {
        toast.error(`${result.status_code}: ${result.message}`);
        setLoading(false);
        return;
      }

      window.location.href = `${result.data.redirectUrl}?accessToken=${result.data.accessToken}`;
      setLoading(false);
    },
  });
  return (
    <div className="flex h-screen w-screen p-5 items-center justify-center md:space-x-24 flex-col md:flex-row">
      <div className="flex items-center justify-center space-x-4">
        <img src={"/logo.webp"} className="w-[120px] mb-2" />
        <h3 className="text-left uppercase font-bold">
          Нэгдсэн бүртгэлийн <br /> систем
        </h3>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="border max-w-full w-[350px] border-gray-300 p-5 flex flex-col  rounded shadow"
      >
        <label className="font-bold block mb-3" htmlFor="email">
          Имэйл хаяг: <span className="text-red-500">*</span>
        </label>
        <div className="relative mb-6">
          <input
            id="email"
            name="email"
            type="text"
            autoFocus
            placeholder="john"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className="border rounded px-4 py-2 focus:outline-none focus:border-gray-400 block w-full"
          />
          <div
            className={`text-xs transform text-red-500 -bottom-[15px] transition-all left-0 absolute ${
              formik.touched.email && formik.errors.email
                ? "opacity-100 translate-y-[5px]"
                : "opacity-0 translate-y-0"
            }`}
          >
            {formik.touched.email && formik.errors.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        <label className="font-bold block mb-3" htmlFor="password">
          Нууц үг: <span className="text-red-500">*</span>
        </label>
        <div className="relative mb-8">
          <input
            id="password"
            name="password"
            type="password"
            placeholder="********"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className="border rounded px-4 py-2 focus:outline-none focus:border-gray-400 block w-full"
          />
          <div
            className={`text-xs text-red-500 -bottom-[15px] transition-all left-0 absolute ${
              formik.touched.password && formik.errors.password
                ? "opacity-100 translate-y-[5px]"
                : "opacity-0 translate-y-0"
            }`}
          >
            {formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
          </div>
        </div>

        <button
          type="submit"
          className="block w-full relative px-4 py-2 bg-black focus:outline-none active:bg-black/80 rounded text-white uppercase font-bold"
        >
          <span className={loading ? "opacity-0" : ""}>Нэвтрэх</span>
          {loading && (
            <div className="absolute bg-white/20 inset-0 flex items-center justify-center">
              <div className="animate-spin">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  height="24px"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-white"
                >
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
              </div>
            </div>
          )}
        </button>
      </form>

      <div className="fixed bottom-0 left-0 right-0 flex justify-center p-5">
        <code className="flex text-sm items-center justify-center space-x-2">
          <span>Made with</span>{" "}
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 1024 1024"
            className="inline-block text-red-500"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z"></path>
          </svg>{" "}
          <span>
            by{" "}
            <a
              className="underline"
              href="https://steppelink.mn"
              target="_blank"
              rel="noreferrer"
            >
              Steppelink LLC
            </a>
          </span>
        </code>
      </div>
    </div>
  );
};

export default SignInPage;
