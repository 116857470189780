import { BrowserRouter } from "react-router-dom";
import SignInPage from "./pages/SignIn";

import { ToastContainer } from "react-toastify";

function App() {
  return (
    <BrowserRouter>
      <SignInPage />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
