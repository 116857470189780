import { axiosClient } from "../libs";

const apiAuth = {
    signIn: async (client: string, username: string, password: string) => {
        try {
            const res: any = await axiosClient.post("/client/login", {
                client,
                username,
                password,
            });

            if (res.data?.status_code !== 0) {
                return {
                    success: false,
                    message: res.data?.message,
                    status_code: res.data?.status_code,
                };
            }

            return { success: true, data: res?.data?.data };
        } catch (e) {
            return { success: false, e };
        }
    },
};

export default apiAuth;
